<template>
  <div class="sm:mt-40 flex flex-col items-center">
    <template v-if="!emailSent">
      <section-heading :heading="$t('link_expired')" />
      <svg xmlns="http://www.w3.org/2000/svg" width="99" height="124" viewBox="0 0 99 124" v-bind:svg-inline="''" v-bind:src="'@/assets/images/expiredConfirmation/expresionless.svg'"><g fill="none" fill-rule="evenodd"><path fill="#6284D6" fill-rule="nonzero" d="M15.107 0H83.73c6.686 0 12.152 5.331 12.32 12.015l2.47 98.488c.17 6.804-5.207 12.458-12.01 12.629-.104.003-.207.004-.31.004H12.637c-6.806 0-12.324-5.518-12.324-12.324 0-.103.001-.206.004-.309l2.47-98.488C2.955 5.33 8.421 0 15.107 0z"/><g fill="#00292B" transform="translate(57 28)"><circle cx="1.5" cy="1.5" r="1.5"/><circle cx="19.5" cy="1.5" r="1.5"/></g><path fill="#E8F679" fill-rule="nonzero" d="M17.382 36.788l16.114.844-.844 16.114-16.114-.844z"/><path fill="#FFF" fill-opacity=".4" fill-rule="nonzero" d="M30.638 53.64a2.017 2.017 0 002.12-1.908l-2.014-.106-.106 2.015z"/><path stroke="#9893C0" stroke-width="1.009" style="mix-blend-mode:multiply" stroke-linecap="square" d="M21.624 42.565l8.058.422m-8.217 2.599l6.044.316"/><g><path fill="#67D1E1" fill-rule="nonzero" stroke="#67D1E1" stroke-width=".5" d="M7.486 58.977l16.097-1.125 1.125 16.097-16.097 1.125z"/><path fill="#FFF" fill-opacity=".4" fill-rule="nonzero" d="M22.697 74.09a2.017 2.017 0 001.87-2.154l-2.01.141.14 2.012z"/><path stroke="#9893C0" stroke-width="1.009" style="mix-blend-mode:multiply" stroke-linecap="square" d="M12.4 64.194l8.05-.562m-7.84 3.58l6.038-.422"/></g></g></svg>
      <i18n-t keypath="expired" tag="paragraph" class="text-xl text-center mt-8 font-light">
        <template #break>
          <br />
        </template>
        <template #email>
          <span class="text-gray-900 dark:text-gray-100">{{ email }}</span>
        </template>
      </i18n-t>
      <pill-button class="mt-4" @click="resendEmail">
        {{ $t('new_link') }}
      </pill-button>
    </template>
    <template v-else>
      <section-heading :heading="$t('check_email')" />
      <svg xmlns="http://www.w3.org/2000/svg" width="99" height="124" viewBox="0 0 99 124" v-bind:svg-inline="''" v-bind:src="'@/assets/images/expiredConfirmation/smile.svg'"><g fill="none" fill-rule="evenodd"><path fill="#6284D6" fill-rule="nonzero" d="M15.107 0H83.73c6.686 0 12.152 5.331 12.32 12.015l2.47 98.488c.17 6.804-5.207 12.458-12.01 12.629-.104.003-.207.004-.31.004H12.637c-6.806 0-12.324-5.518-12.324-12.324 0-.103.001-.206.004-.309l2.47-98.488C2.955 5.33 8.421 0 15.107 0z"/><g transform="translate(57 28)"><path stroke="#00292B" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M17.156 12a8.987 8.987 0 01-6.7 3 8.98 8.98 0 01-6.706-2.997"/><circle cx="1.5" cy="1.5" r="1.5" fill="#00292B"/><circle cx="19.5" cy="1.5" r="1.5" fill="#00292B"/></g><path fill="#E8F679" fill-rule="nonzero" d="M17.382 36.788l16.114.844-.844 16.114-16.114-.844z"/><path fill="#FFF" fill-opacity=".4" fill-rule="nonzero" d="M30.638 53.64a2.017 2.017 0 002.12-1.908l-2.014-.106-.106 2.015z"/><path stroke="#9893C0" stroke-width="1.009" style="mix-blend-mode:multiply" stroke-linecap="square" d="M21.624 42.565l8.058.422m-8.217 2.599l6.044.316"/><g><path fill="#67D1E1" fill-rule="nonzero" stroke="#67D1E1" stroke-width=".5" d="M7.486 58.977l16.097-1.125 1.125 16.097-16.097 1.125z"/><path fill="#FFF" fill-opacity=".4" fill-rule="nonzero" d="M22.697 74.09a2.017 2.017 0 001.87-2.154l-2.01.141.14 2.012z"/><path stroke="#9893C0" stroke-width="1.009" style="mix-blend-mode:multiply" stroke-linecap="square" d="M12.4 64.194l8.05-.562m-7.84 3.58l6.038-.422"/></g></g></svg>
      <i18n-t keypath="follow_link" tag="paragraph" class="text-xl text-center mt-8 font-light">
        <template #break>
          <br />
        </template>
        <template #email>
          <span class="text-gray-900 dark:text-gray-100">{{ email }}</span>
        </template>
      </i18n-t>
      <router-link to="/">
        <pill-button class="mt-4">{{ $t('back_home') }}</pill-button>
      </router-link>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import componentI18n from '@/i18n/componentI18n';
import SectionHeading from '../../components/base/headings/SectionHeading.vue';

export default {
  name: 'ConfirmationExpired',
  components: { SectionHeading },
  ...componentI18n('views.auth.confirmation_expired'),
  data() {
    return {
      email: this.$route.params.email,
      emailSent: false
    };
  },
  methods: {
    ...mapActions(['resendConfirmationEmail']),
    resendEmail() {
      this.resendConfirmationEmail(this.email);
      this.emailSent = true;
    }
  }
};
</script>
